<template>
    <app-link
            class="grid-item"
            :to="routeToPlayer"
    >
        <div class="item-poster">
            <image-component :path="image" alt=""/>
            <div class="preview">
                <image-component v-if="preview" :path="preview" :alt="`${title} preview`"/>
            </div>
            <div class="play_btn">
                <i class="fas fa-play"></i>
            </div>
        </div>
        <div class="item-description">
            <p v-if="title" class="title" :title="title">{{title}}</p>
            <p v-if="formattedDate" class="subtitle">{{formattedDate}}</p>
        </div>
    </app-link>
</template>

<script>
    import {ASSETS_TYPE} from "../../../const";
    import ImageComponent from "../../../components/base-components/ImageComponent";
    import AppLink from "../../../components/base-components/AppLink";

    export default {
        name: "VodGridItem",
        components: {AppLink, ImageComponent},
        props: {
            vodInfo: Object,
        },
        data() {
            return {
                image: this.vodInfo.assets?.find(asset => asset.type === ASSETS_TYPE.image)?.path,
                title: this.vodInfo.title,
                preview: this.vodInfo.assets?.find(asset => asset.type === ASSETS_TYPE.preview)?.path,
            }
        },
        computed: {
            routeToPlayer(){
                return {
                    name: 'watch library',
                    params: {
                        id: this.vodInfo.id
                    }
                }
            },
            formattedDate() {
                if (!this.vodInfo.date) return null;
                const t = new Date(this.vodInfo.date)
                const year = t.getFullYear(),
                    month = t.toLocaleString('en', {month: 'long'}),
                    day = t.getDate()

                return `${day} ${month}, ${year}`
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "src/assets/css/colors";
    @import "src/assets/css/mixins";
    @import "src/assets/css/fonts";

    .grid-item{
        display: flex;
        flex-direction: column;
        overflow: hidden;
        word-break: break-all;
        position: relative;
        cursor: pointer;
        text-decoration: none;

        &:hover{
          .preview{
              display: block;
              visibility: visible;
          }
        }
        .preview{
            display: none;
            visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .item-poster{
            position: relative;
            @include ratio(calc(16 / 9));
            img{
                width: 100%;
                object-fit: cover;
            }

            margin-bottom: 1rem;
        }

        .subtitle{
            font-size: .7rem;
            font-family: $font-main;
            font-weight: 400;
            font-style: italic;
            color: var(--vod-subtitle-color);
        }
        .title{
            margin-bottom: .5rem;
            color: var(--vod-title-color);
            word-break: break-word;
            @include line-clamp(2);
        }
        .item-description{
            text-align: left;
        }
        .play_btn{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            justify-content: center;
            align-items: center;
            display: flex;
            i {
                border: 1px solid $text-secondary;
                padding: 1rem;
                border-radius: 50%;
                color: $text-secondary;
                &:hover{
                    background: rgba($text-secondary-rgb, .3);
                }
            }
        }
    }

</style>