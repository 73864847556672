<template>
    <div class="wrapper">
        <div class="content-wrapper">
                <div
                        class="grid-wrapper"
                        v-if="gridData.length"
                >
                    <vod-grid-item
                            v-for="info in gridData"
                            :vod-info="info"
                            :key="info.id"
                    />
                </div>
                <div v-else-if="!gridData.length && processing">
                    <Loader/>
                </div>
                <div
                        v-else
                        class="empty-category"
                >
                    {{$t('Empty category')}}
                </div>

        </div>
        <div
                v-if="totalElements && moreOnePage"
                class="pagination-wrapper"
        >
            <div v-if="processing">
                <Loader/>
            </div>
            <ButtonComponent
                    v-if="!processing && showLoadButton"
                    :text="$t('Show more')"
                    @btn-click="onShowMore"
            />
        </div>
    </div>
</template>



<script>
  import {mapActions} from "vuex";
  import VodGridItem from "./VodGridItem";
  import Loader from "../../../components/Loader";
  import ButtonComponent from "../../../components/base-components/buttons/ButtonComponent";

  const CATEGORY_LIMIT = 12;
  const CATEGORY_OFFSET = 0;
  const CATEGORY_SORT = 'date'; //[id|date|weight]
  const CATEGORY_DIRECTION = 'desc';//[asc|desc]

  export default {
    name: "VodGrid",
    components: {ButtonComponent, Loader, VodGridItem},
    props: {
      categoryId: {
        type: Number,
        default: 0
      },
      limit: {
        type: Number,
        default: CATEGORY_LIMIT,
      },
      initialOffset: {
        type: Number,
        default: CATEGORY_OFFSET,
      },
      sort_field: {
        type: String,
        default: CATEGORY_SORT,
      },
      sort_direction: {
        type: String,
        default: CATEGORY_DIRECTION,
      },
    },
    data(){
      return {
        gridData: [],
        page: 1,
        offset: this.initialOffset,
        processing: false,
        totalElements: 0,
        abortController: null,
        showLoadButton: false,
      }
    },
    computed:{
      moreOnePage(){
        return (this.totalElements / this.limit) > 1
      }
    },
    watch: {
      page(newPage){
        const currentOffset = this.limit * (newPage - 1);
        this.offset = currentOffset;
        this.getMediaItems()
      },
      categoryId(newId, oldId){
        if (newId !== oldId){
          const currentPage = this.page;
          this.page = 1;
          this.gridData = []
          currentPage === 1 && this.getMediaItems();
        }
      }
    },

    methods:{
      ...mapActions(['GET_VOD_ITEMS']),
      getRequestParams(){
        const controller = new AbortController();
        this.abortController = controller;
        const props = {
          limit: this.limit,
          offset: this.offset,
          sort_field: this.sort_field,
          sort_direction: this.sort_direction,
          abortSignal: controller.signal,
          recursively: 1
        }
        this.categoryId && (props.vod_media_category_id = this.categoryId)

        return props
      },
      getMediaItems(){
        this.processing = true;
        if(this.abortController) {
          this.abortController?.abort?.()
        }
        this.GET_VOD_ITEMS(this.getRequestParams())
            .then(res => {
              const meta = res.meta;
              this.totalElements = meta.total
              this.showLoadButton = meta.total > meta.limit + meta.offset;
              this.concatGridElements(res.data)
            })
            .catch( err => {
              this.gridData = [];
              this.totalElements = 0
            })
            .finally(() => {
              this.processing = false;
              this.abortController = null;
            })
      },
      concatGridElements(elements = []){
        this.gridData = [...this.gridData, ...elements]
      },
      onShowMore(){
        this.page += 1;
      }
    },
    mounted() {
      this.getMediaItems()
    }
  }
</script>

<style scoped lang="scss">
    @import "src/assets/css/mixins";
    @import "src/assets/css/colors";


    .wrapper{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .content-wrapper{
        flex-grow: 1;
    }
    .grid-wrapper{
        --vod-title-color:  var(--text-primary);
        --vod-subtitle-color: var(--text-primary);

        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
        gap: 1.5rem;
        @include media-max(xs){
            grid-template-columns: 1fr;
        }
    }

    .empty-category{
        font-size: 2rem;
        line-height: 2;
    }

    .pagination-wrapper{
        max-width: 40rem;
        width: 100%;
        margin: 3rem auto 2rem;
    }
</style>